.mega-menu {
  position: static;
}

.mega-menu__content {
  background-color: #fff;
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  left: 0;
  overflow: hidden;
  padding-bottom: 3rem;
  padding-top: 5rem;
  position: absolute;
  right: 0;
  top: 7rem;
  z-index: 999;
}

.shopify-section-header-sticky .mega-menu__content {
  max-height: calc(100vh - var(--header-bottom-position-desktop, 20rem) - 4rem);
}

.header-wrapper--border-bottom .mega-menu__content {
  border-top: 0;
}

.js .mega-menu__content {
  opacity: 0;
  transform: translateY(-1.5rem);
}

.mega-menu[open] .mega-menu__content {
  opacity: 1;
  transform: translateY(-1rem);
}

.mega-menu__list {
  display: grid;
  /* gap: 1.8rem 10rem; */
  grid-template-columns: repeat(4, minmax(0, 1fr));
  list-style: none;
  padding: 0 10rem;
}

.mega-menu__list > li {
  padding: 0 20px;
}

.mega-menu__link {
  color: #000 !important;
  display: block;
  line-height: calc(1 + 0.3 / var(--font-body-scale));
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
  text-decoration: none;
  transition: text-decoration var(--duration-short) ease;
  word-wrap: break-word;
}

.mega-menu__link--level-2 {
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: 2px;
}

.header--top-center .mega-menu__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 0;
}

.header--top-center .mega-menu__list > li {
  width: 16%;
  padding-right: 2.4rem;
}

.mega-menu__link:hover,
.mega-menu__link--active {
  color: rgb(var(--color-foreground));
  font-weight: 700;
}

.mega-menu__link--active:hover {
  text-decoration-thickness: 0.2rem;
}

.mega-menu .mega-menu__list--condensed {
  display: block;
}

.mega-menu__list--condensed .mega-menu__link {
  font-weight: normal;
}
